<template>
  <div>
    <app-cells position="start">
      <app-button
        size="link"
        @click="onShowModalProtocol"
      >
        <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
        <span>Скачать протокол</span>
      </app-button>
      <app-button
        size="link"
        :class="{'btn--loading': download_team}"
        @click="onDownloadTeam"
      >
        <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
        <span>Общекомандный зачет</span>
      </app-button>
    </app-cells>
    <form @submit.prevent="onCheckForm">
      <app-grid>
        <template #item-1>
          <app-form-group required label="Направление">
            <v-select
              :options="directions_options"
              v-model="form.direction"
              @input="onChangeDirection"
              :filterable="false"
              :clearable="false"
              :searchable="false"
              label="name"
              placeholder="Выберите значение"
              class="select"
              :class="{ 'select--error': $v.form.direction.$error }"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
              <template #no-options>Ничего не найдено</template>
            </v-select>
            <template #error>
              <div v-if="$v.form.direction.$dirty && !$v.form.direction.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group v-if="form.direction && (form.direction !== 'region' ||  app_name === 'oovo')" label="Номинация">
            <v-select
              v-model="form.nomination"
              :options="nominations_options"
              @input="onChangeNomination"
              :filterable="true"
              :clearable="false"
              label="name"
              placeholder="Выберите значение"
              class="select"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
              <template #no-options>Ничего не найдено</template>
            </v-select>
          </app-form-group>
          <app-form-group v-if="form.nomination && form.nomination.has_subnominations" label="Подноминация">
            <v-select
              v-model="form.subnomination"
              :reduce="item => item.id"
              :options="subnominations_options"
              @input="onChangeSubNomination"
              :filterable="false"
              :clearable="false"
              :searchable="false"
              label="name"
              placeholder="Выберите значение"
              class="select"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
              <template #no-options>Ничего не найдено</template>
            </v-select>
          </app-form-group>
        </template>
        <template #item-2>
<!--          <app-form-group v-if="form.direction && ['common', 'fashion', 'art', 'journal'].includes(form.direction.code)" label="Категория">-->
<!--            <app-cells position="start" :indent="false" class="cells&#45;&#45;radio">-->
<!--              <app-radio-->
<!--                v-for="category in category_options"-->
<!--                :key="category.id"-->
<!--                v-model.number="form.category"-->
<!--                :value="category.id"-->
<!--                :label="category.name"-->
<!--                name="category"-->
<!--              />-->
<!--            </app-cells>-->
<!--          </app-form-group>-->
          <app-form-group v-if="form.direction && ['common', 'fashion', 'art', 'journal'].includes(form.direction.code)" label="Форма участия">
            <v-select
              v-model="form.amount"
              :options="amount_options"
              :reduce="item => item.id"
              :filterable="false"
              :clearable="false"
              :searchable="false"
              label="name"
              placeholder="Выберите значение"
              class="select"
              :readonly="amountReadonly"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
              <template #no-options>Ничего не найдено</template>
            </v-select>
          </app-form-group>
        </template>
      </app-grid>
      <app-cells>
        <app-button ref="submit" :disabled="$v.form.$error">Сформировать список номеров</app-button>
      </app-cells>
    </form>
    <v-client-table
      v-if="table.items.length"
      @row-click="onRowClick"
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="tableDefault"
      class="table-default table-default--dynamic"
    >
      <template #number_author="props">
        {{ props.row.number_author }}<br>{{ props.row.number_name ? props.row.number_name : '-' }}
      </template>
      <template #nomination="props">
        {{ props.row.nomination ? props.row.nomination : '-' }}<br>{{ props.row.amount ? props.row.amount : '-' }}
      </template>
      <template #awards="props">
        <span v-if="props.row.awards.length">
          <span
            v-for="item of props.row.awards"
            :key="item.award_name"
          >
            {{ item.award_name }}<br>
          </span>
        </span>
        <span v-else>-</span>
      </template>
    </v-client-table>
    <modal
      name="award-add"
      :width="750"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
    >
      <div class="modal">
        <div class="modal__title modal__title--center-black">Добавление награды</div>
        <div class="modal__award">
          <app-form-group label="Название награды" required>
            <v-select
              v-model="award.award_name"
              :reduce="item => item.name"
              :options="awards_options"
              :filterable="false"
              :clearable="false"
              :searchable="false"
              label="name"
              placeholder="Выберите значение"
              class="select"
              :class="{ 'select--error': $v.award.award_name.$error }"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
              <template #no-options>Ничего не найдено</template>
            </v-select>
            <template #error>
              <div v-if="$v.award.award_name.$dirty && !$v.award.award_name.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label="Описание" label-for="description">
            <app-textarea
              v-model="award.description"
              id="description"
              placeholder="Введите значение"
            />
          </app-form-group>
        </div>
        <app-cells position="center">
          <app-button size="small" @click="onAddAward" ref="reviewSubmit" :disabled="$v.award.$error">Добавить награду</app-button>
        </app-cells>
      </div>
    </modal>
    <modal
      name="award-download"
      :width="750"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      class="overflow-visible"
    >
      <div class="modal">
        <div class="modal__title modal__title--center-black">Скачать протокол</div>
        <div class="modal__award">
          <app-form-group label="Направление">
            <v-select
              v-model="direction"
              :options="directions_options"
              :filterable="false"
              :clearable="false"
              :searchable="false"
              label="name"
              placeholder="Выберите значение"
              class="select"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
              <template #no-options>Ничего не найдено</template>
            </v-select>
          </app-form-group>
        </div>
        <app-cells position="center">
          <app-button size="small" @click="onDownloadProtocol" ref="downloadProtocol" :disabled="!direction">Скачать протокол</app-button>
        </app-cells>
      </div>
    </modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  getAmount,
  getDirections,
  getNominations,
  getSubNominations,
  getProgramAwards,
  getAwardsDict,
  postAward,
  getAwardsExcel,
  getAwardsProtocol
} from '@/http'

export default {
  name: 'Awards',
  data() {
    return {
      download_team: false,
      direction: '',
      table: {
        items: [],
        columns: ['pk', 'delegation', 'number_author', 'nomination', 'avg_score', 'awards'],
        options: {
          headings: {
            pk: 'ID',
            delegation: 'Делегация',
            number_author: 'Исполнитель / Название номера',
            nomination: 'Номинация / Форма участия',
            avg_score: 'Средний балл',
            awards: 'Награды',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 10000,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Вы еще не добавили ни одного номера',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => 'table-default__row',
        },
      },
      form: {
        direction: '',
        subnomination: '',
        nomination: '',
        amount: '',
      },
      award: {},
      directions_options: [],
      nominations_options: [],
      subnominations_options: [],
      amount_options: [],
      // category_options: [
      //   {
      //     id: 1,
      //     name: 'Профильная',
      //   },
      //   {
      //     id: 2,
      //     name: 'Непрофильная',
      //   },
      // ],
      awards_options: [],
      award_number: null,
      app_name: process.env.VUE_APP_FESTIVAL_NAME,
    }
  },
  validations: {
    form: {
      direction: { required }
    },
    award: {
      award_name: { required }
    }
  },
  computed: {
    amountReadonly() {
      return !this.form.nomination || (this.form.nomination.has_subnominations && !this.form.subnomination)
    },
  },
  created() {
    getDirections()
      .then(response => {
        this.directions_options = response.data
      })
    getAwardsDict()
      .then(response => {
        this.awards_options = response.data
      })
  },
  methods: {
    onDownloadProtocol() {
      this.$refs.downloadProtocol.preload = true
      getAwardsProtocol(this.direction.id)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Протокол ${this.direction.name}.docx`)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .finally(() => {
          this.$refs.downloadProtocol.preload = false
          this.onHideModalProtocol()
        })
    },
    onDownloadTeam() {
      this.download_team = true
      getAwardsExcel()
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Общекомандный зачет.xlsx')
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .finally(() => {
          this.download_team = false
        })
    },
    onShowModal() {
      this.$modal.show('award-add')
    },
    onHideModal() {
      this.$modal.hide('award-add')
    },
    onShowModalProtocol() {
      this.$modal.show('award-download')
    },
    onHideModalProtocol() {
      this.$modal.hide('award-download')
    },
    onChangeDirection(val) {
      this.form.nomination = ''
      if (val.code === 'region' && this.app_name !== 'oovo') return
      getNominations(this.form.direction.id)
        .then(response => {
          this.nominations_options = response.data
        })
    },
    onChangeNomination() {
      this.form.subnomination = ''
      this.form.amount = ''
      // const nominationId = this.nominations_options.find(item => item.id === this.form.nomination.id).id
      getSubNominations(this.form.nomination.id)
        .then(response => {
          this.subnominations_options = response.data
        })
      getAmount(this.form.direction.id, this.form.nomination.id)
        .then(response => {
          this.amount_options = response.data
        })
    },
    onChangeSubNomination() {
      this.form.amount = ''
      getAmount(this.form.direction.id, this.form.nomination.id, this.form.subnomination)
        .then(response => {
          this.amount_options = response.data
        })
    },
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      this.getList()
    },
    getList () {
      const params = {
        Direction: this.form.direction.id,
        Nomination: this.form.nomination.id || '',
        Sumnomination: this.form.subnomination || '',
        Amount: this.form.amount || '',
        // Category: this.form.category || ''
      }
      getProgramAwards(params)
        .finally(() => {
          this.$refs.submit.preload = false
        })
        .then(response => {
          this.table.items = response.data
        })
    },
    onAddAward() {
      this.$v.award.$touch()
      if (this.$v.award.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.sendAward()
      }
    },
    sendAward() {
      this.$refs.reviewSubmit.preload = true
      postAward(this.award_number, this.award)
        .finally(() => {
          this.$refs.reviewSubmit.preload = false
        })
        .then(() => {
          this.getList()
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Награда добавлена'
          })
          this.onHideModal()
        })
    },
    onRowClick(row) {
      this.award_number = row.row.pk
      this.award.description = ''
      this.award.award_name = ''
      this.$v.award.$reset()
      this.onShowModal()
    },
  }
}
</script>

